import { FynbosLogo, Icon, Icons, Router } from '@/Components'
import { cn } from '@/Lib'
import { Learn } from '@/types/learn'
import { InertiaLinkProps, Link, usePage } from '@inertiajs/react'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { motion, useMotionValueEvent, useScroll } from 'framer-motion'
import {
  FC,
  ReactNode,
  forwardRef,
  useMemo,
  useState,
  type RefAttributes
} from 'react'
import { NavDrawer } from './NavDrawer'

const VITE_APP_URL = import.meta.env.VITE_APP_URL || 'https://fynbos.money'

type Props = Learn

export const MarketingHeader: FC = () => {
  const { nav } = usePage<Props>().props
  return (
    <header className='sticky top-0 z-20 box-border flex w-full border-b border-slate-200 bg-white/95 md:h-24 [@supports(backdrop-filter:blur(0))]:bg-white/80 [@supports(backdrop-filter:blur(0))]:backdrop-blur'>
      <div className='flex w-full items-center gap-x-4 p-4 md:p-6 lg:justify-between'>
        <div className='lg:hidden'>
          <NavDrawer>
            <AccordionPrimitive.Root
              type='multiple'
              className='flex flex-col gap-1'
            >
              {nav &&
                nav.data.allLearnV2s?.map((record) => (
                  <AccordionPrimitive.Item key={record.id} value={record.id}>
                    <AccordionPrimitive.Header>
                      <AccordionPrimitive.Trigger className='flex w-full items-center justify-between rounded-xl p-3 text-sm text-medium hover:bg-nav data-[state=open]:text-strong [&[data-state=open]>span]:rotate-180'>
                        {record.title}
                        <Icon className='transition-transform duration-200'>
                          expand_more
                        </Icon>
                      </AccordionPrimitive.Trigger>
                    </AccordionPrimitive.Header>
                    <AccordionPrimitive.Content className='flex flex-col gap-1 pl-4 data-[state=open]:py-1'>
                      {record.children?.map((section) => (
                        <SideNavItem
                          href={section?.slug || ''}
                          key={section?.id}
                        >
                          {section?.title}
                        </SideNavItem>
                      ))}
                    </AccordionPrimitive.Content>
                  </AccordionPrimitive.Item>
                ))}
            </AccordionPrimitive.Root>
            <SideNavItem
              href={route('marketing.pricing')}
              className={nav && 'mt-8'}
            >
              <Icon>sell</Icon>Pricing
            </SideNavItem>
            <SideNavItem href={route('learn')}>
              <Icon>local_library</Icon>Learn
            </SideNavItem>
            <SideNavItem href={route('login')}>
              <Icon>arrow_right_alt</Icon>Start
            </SideNavItem>
          </NavDrawer>
        </div>
        <Router href={'/'}>
          <FynbosLogo />
        </Router>
        <div className='ml-10 hidden gap-2 font-medium text-strong lg:flex'>
          <NavItem href={route('marketing.pricing')} className='text-strong'>
            Pricing
          </NavItem>
          <NavItem href={route('learn')}>Learn</NavItem>
        </div>
        <StartButton href={route('login')} />
      </div>
    </header>
  )
}

type SideNavItemProps = {
  children?: ReactNode
  href: string
  className?: string
  type?: 'doc' | 'section'
}

const SideNavItem: FC<SideNavItemProps> = ({
  className,
  children,
  href,
  type
}) => {
  return (
    <Router
      href={href}
      className={cn(
        type == 'section' && 'pl-8',
        'relative flex w-full items-center gap-2 truncate rounded-xl p-3 focus-visible:outline focus-visible:outline-2 focus-visible:outline-focus',
        className
      )}
    >
      {children}
    </Router>
  )
}

SideNavItem.displayName = 'ListItem'

export const NavItem = forwardRef<
  HTMLAnchorElement,
  InertiaLinkProps & RefAttributes<HTMLAnchorElement>
>(({ className, href, children, ...props }, ref) => {
  const { url } = usePage()

  const isActive = useMemo(() => {
    const active = new URL(url, VITE_APP_URL)
    const current = new URL(href, VITE_APP_URL)

    return active.pathname.startsWith(current.pathname)
  }, [url, href])

  return (
    <Link
      ref={ref}
      href={href}
      className={cn(
        isActive ? 'bg-slate-50' : '',
        'group ml-auto flex w-full max-w-max items-center rounded-full px-8 py-4 transition-colors hover:bg-slate-100',
        className
      )}
      {...props}
    >
      <span className='font-medium leading-none text-medium'>{children}</span>
    </Link>
  )
})

NavItem.displayName = 'ListItem'

export const StartButton = forwardRef<
  HTMLAnchorElement,
  InertiaLinkProps & RefAttributes<HTMLAnchorElement>
>(({ className, ...props }, ref) => {
  const { scrollY } = useScroll()
  const [threshold, setThreshold] = useState<boolean>(false)

  const pageHeight = typeof window != 'undefined' ? window.innerHeight / 1.8 : 0

  useMotionValueEvent(scrollY, 'change', (latest) => {
    if (latest > pageHeight && !threshold) setThreshold(true)
    else if (latest < pageHeight && threshold) setThreshold(false)
  })

  return (
    <Link
      ref={ref}
      className={cn(
        'group ml-auto flex w-full max-w-max items-center rounded-full bg-nav p-1 transition-colors hover:bg-nav-hover',
        className
      )}
      {...props}
    >
      <span className='ml-4 mr-4 font-medium leading-none text-medium'>
        Start
      </span>
      <motion.div
        className={cn(
          'flex items-center justify-center rounded-full bg-nav p-2 transition-colors duration-300 group-hover:bg-green-400 group-hover:text-white',
          threshold && 'bg-green-400 text-white'
        )}
      >
        <Icon>{Icons.arrow_right_alt}</Icon>
      </motion.div>
    </Link>
  )
})

StartButton.displayName = 'StartButton'

import { cn } from '@/Lib'
import type { FC, HTMLAttributes } from 'react'

/**
 * Implements all the custom mark types
 * We use tailwind prose for this, as it then filters down to code blocks too. It also comes with sane defaults.
 */
export const Prose: FC<HTMLAttributes<HTMLDivElement>> = ({
  className,
  children
}) => {
  return (
    <div
      className={cn(
        'prose prose-slate text-pretty',
        'prose-h1:font-medium',
        'prose-h2:font-medium',
        'prose-h3:font-medium',
        'prose-h4:font-medium',
        'prose-h5:font-medium',
        'prose-h6:font-medium',
        'prose-p:my-3',
        'prose-table:my-0',
        'prose-a:rounded prose-a:font-medium prose-a:text-primary prose-a:underline-offset-2 prose-a:focus-visible:outline prose-a:focus-visible:outline-2 prose-a:focus-visible:outline-focus',
        'before:prose-p:content-none prose-blockquote:font-normal prose-blockquote:not-italic prose-blockquote:after:content-none',
        'prose-strong:font-medium',
        'prose-code:-mx-0.5 prose-code:-my-1 prose-code:rounded prose-code:bg-slate-100 prose-code:px-0.5 prose-code:py-1 prose-code:font-sans prose-code:font-normal prose-code:tracking-wider prose-code:before:content-none prose-code:after:content-none',
        className
      )}
    >
      {children}
    </div>
  )
}
